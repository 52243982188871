import React, { useEffect, useState } from 'react';
import '@pds-react/fileUpload/dist/fileUpload.min.css';
import '@pds-react/fileUpload/dist/icon.min.css';
import '@pds-react/fileUpload/dist/button.min.css';
import '@pds-react/fileUpload/dist/error.min.css';
import '@pds-react/fileUpload/dist/label.min.css';
import '@pds-react/fileUpload/dist/modal.min.css';
import '@pds-react/fileUpload/dist/helperText.min.css';
import Dropzone, { Accept, FileRejection, FileWithPath } from 'react-dropzone';
import Icon from '@pds-react/icon';
import PropTypes, { InferProps } from 'prop-types';
import ErrorSpan from './ErrorSpan';

export interface CustomFile {
    errorStatus: boolean;
    file: FileWithPath;
}

function FileUpload(props: InferProps<typeof FileUpload.propTypes>) {
    const [show, setModalState] = useState(false);
    const [isFileAttachedRecently, setFileAttachedEventRecently] = useState(false);
    const [rejectedFiles, setRejectedFiles] = useState<Array<FileRejection>>([]);
    const [isFileSizeZeroKb, setFileSizeZeroKb] = useState(false);
    const filesInFormContext = props.watch(props.id);

    const onDrop = (acceptedFiles: Array<File>) => {
        setFileAttachedEventRecently(true);
        props.setValue(props.id, [
            ...filesInFormContext,
            ...acceptedFiles.map((file) => {
                return {
                    errorStatus: false,
                    file
                };
            })
        ]);
    };

    const removeFile = (fileName: string) => {
        const updatedFiles = filesInFormContext.filter(
            (file: { file: { name: string } }) => file.file.name !== fileName
        );
        props.setValue(`${props.id}`, updatedFiles);
    };

    const handleModalClose = () => {
        setModalState(false);
        setFileAttachedEventRecently(false);
        setFileSizeZeroKb(false);
    };

    const allowedFileTypes: Accept = {
        'application/pdf': ['.pdf'],
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/tiff': ['.tiff', '.tif']
    };

    const approx1MB = 1048576;
    const oneByte = 1;
    const hasError = props.errors && props.errors[props.id];

    useEffect(() => {
        if (filesInFormContext?.length > 0) props.clearErrors(props.id);
    }, [props.id, props.clearErrors, filesInFormContext?.length]);

    return (
        <>
            <Dropzone multiple
                onDrop={onDrop}
                maxSize={approx1MB}
                minSize={oneByte}
                accept={allowedFileTypes}
                data-testid={props.id}
                noClick
                noKeyboard
            >
                {({
                    getRootProps,
                    getInputProps,
                    open,
                    fileRejections,
                    isDragActive
                }) => {
                    if (fileRejections.length !== 0 && isFileAttachedRecently) {
                        setRejectedFiles(fileRejections);
                        setModalState(true);
                        fileRejections.forEach((value) => {
                            if (value.file.size === 0) {
                                setFileSizeZeroKb(true);
                            }
                        });
                    }

                    return (
                        <div style={{ marginTop: '16px' }}>
                            <div {...getRootProps({
                                className: `dropzone pds-fileUpload ${isDragActive ? 'pds-fileUpload-dragover' : ''
                                }`
                            })}
                            >
                                <label htmlFor={props.id}
                                    className="pds-label"
                                    aria-required
                                >
                                    <b>
                                        {props.labelText}
                                        {' '}
                                    </b>
                                    {props.required && <span className="pds-label-required">*</span>}
                                </label>
                                <div className="pds-helperText"
                                    id={`${props.id}-helperText`}
                                    style={{ marginTop: '8px' }}
                                >
                                    You can upload a file up to 1 MB in PDF, PNG, TIFF, TIF, JPEG or JPG Format.
                                    <b> Please note that attachments will not be e-signed. </b>
                                </div>
                                {props.required && filesInFormContext && filesInFormContext.length === 0 ? (
                                    <div>
                                        <input {...getInputProps()}
                                            id={props.id}
                                            data-testid={`${props.id}-input`}
                                            className="sr-only"
                                            onClick={() => props.clearErrors(props.id)}
                                            {...props.register}
                                        />
                                        <ErrorSpan hasError={hasError} errors={props.errors} />
                                    </div>
                                ) : (
                                    <input type="file"
                                        {...getInputProps()}
                                        className="sr-only"
                                        id={props.id}
                                        {...props.register}
                                    />
                                )}
                                <div className="pds-fileUpload-drop-zone">
                                    <div className="pds-color-font-neutral-2">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            focusable="false"
                                            className="feather feather-upload"
                                            data-size="40"
                                            aria-hidden="true"
                                        >
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                            <polyline points="17 8 12 3 7 8" />
                                            <line x1="12" y1="3" x2="12" y2="15" />
                                        </svg>
                                    </div>
                                    <div>
                                        Drop files to attach, or
                                        {' '}
                                        <button id={`${props.id}-button`}
                                            type="button"
                                            className="pds-link"
                                            onClick={open}
                                            style={{ margin: '0px' }}
                                        >
                                            browse
                                        </button>
                                    </div>
                                </div>
                                <div id="pds-file-upload-input-file-list-section">
                                    {filesInFormContext && filesInFormContext.length === 0 ? (
                                        <div className="pds-fileUpload-no-files">
                                            No files have been attached.
                                        </div>
                                    ) : (
                                        <ul className="pds-fileUpload-ul">
                                            {filesInFormContext && filesInFormContext.map(
                                                (file: CustomFile, index: number) => {
                                                    return (
                                                        <li data-file-index={index}
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={`${index}${file.file.path}`}
                                                        >
                                                            <div className="pds-fileUpload-file-card"
                                                                style={{
                                                                    margin: '8px 0px',
                                                                    borderColor: `${file?.errorStatus ? '#C00000' : ''
                                                                    }`
                                                                }}
                                                            >
                                                                <div className="pds-fileUpload-file-icon"
                                                                    style={{
                                                                        color: `${file?.errorStatus ? '#C00000' : ''
                                                                        }`
                                                                    }}
                                                                >
                                                                    <Icon name="paperclip" />
                                                                </div>
                                                                <div className="pds-fileUpload-file-name"
                                                                    style={{
                                                                        color: `${file?.errorStatus ? '#C00000' : ''
                                                                        }`
                                                                    }}
                                                                >
                                                                    {file.file.path}
                                                                </div>
                                                                <button type="button"
                                                                    className="pds-link"
                                                                    style={{
                                                                        borderBottom: 'none',
                                                                        marginLeft: 'auto'
                                                                    }}
                                                                    data-pds-file-upload-remove={index}
                                                                    aria-label={file.file.path}
                                                                    onClick={() => removeFile(file.file.name)}
                                                                >
                                                                    <Icon name="trash-2" />
                                                                </button>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Dropzone>
            <div>
                <div className="pds-modal-dialog-overlay"
                    tabIndex={-1}
                    aria-hidden={!show}
                />
                <div className="pds-modal pds-modal-temp" data-initialized="true">
                    <div className="pds-modal-dialog"
                        role="dialog"
                        aria-labelledby="modal-title"
                        tabIndex={-1}
                        aria-hidden={!show}
                    >
                        <h2 id="modal-title"
                            className="sr-only"
                        >
                            Error Uploading File
                        </h2>
                        <div className="pds-file-upload-modal-content">
                            <p>
                                <strong>The following file could not be uploaded</strong>
                            </p>
                            <ul className="pds-fileUpload-ul">
                                {rejectedFiles.map((rejectFile) => {
                                    return (
                                        <li className="pds-fileUpload-file-error-list-item"
                                            key={rejectFile.file.name}
                                        >
                                            <div className="pds-error">
                                                {' '}
                                                {rejectFile.file.name}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            {isFileSizeZeroKb && (
                                <p>
                                    Please make sure your files are more than 0KB in size and have
                                    one of the following formats:
                                </p>
                            )}
                            {!isFileSizeZeroKb && (
                                <p>
                                    Please make sure your files are less then 1MB in size and
                                    have one of the following formats:
                                </p>
                            )}
                            <ul>
                                <li>
                                    <strong>.pdf</strong>
                                </li>
                                <li>
                                    <strong>.png</strong>
                                </li>
                                <li>
                                    <strong>.jpg, .jpeg</strong>
                                </li>
                                <li>
                                    <strong>.tif, .tiff</strong>
                                </li>
                            </ul>
                        </div>
                        <div className="pds-modal-cta">
                            <button type="button"
                                className="pds-button pds-button-primary pds-modal-close-dialog"
                                data-gtm="modal-cta"
                                onClick={handleModalClose}
                            >
                                Try again
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

FileUpload.propTypes = {
    name: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    required: PropTypes.bool,
    errors: PropTypes.any.isRequired,
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
};

FileUpload.defaultProps = {
    required: false
};

export default FileUpload;
