import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import '@pds/core/core.min.css';
import '@pds/util/util.min.css';
import '@pds/radio/radio.min.css';
import '@pds/input/input.min.css';
import '@pds/label/label.min.css';
import '@pds/select/select.min.css';
import '@pds/button/button.min.css';
import '@pds/erroredInput/erroredInput.min.css';
import '@pds/alert/alert.min.css';
import '@pds/tooltip/tooltip.min.css';
import '@pds/link/link.min.css';
import '@pds/table/table.min.css';
import '@pds/typography/typography.min.css';
import Icon from '@pds-react/icon';
import { useHistory } from 'react-router-dom';
import * as Constants from '../utility/constants';
import TextField from './form-fields/TextField';
import SelectField from './form-fields/SelectField';
import RadioField from './form-fields/RadioField';
import TextAreaField from './form-fields/TextAreaField';
import ExistingInsurance from './form-fields/ExistingInsurance';
import MaskedInput from './form-fields/MaskedInput';
import StepIndicator from './commons/StepIndicator';

function Insured(props: InferProps<typeof Insured.propTypes>) {
    const { register, formState: { errors }, handleSubmit, watch, control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: 'insuredExistingInsurancePolicies',
        control
    });

    const [replacementErrorResponse, setReplacementErrorResponse] = useState(false);

    const history = useHistory();
    const onSubmit = (insuredDetails: any) => {
        if (!replacementErrorResponse) {
            props.setRequest({ ...props.requestData, ...insuredDetails });
            if (insuredDetails.insuredOwnerSame === 'true') {
                history.push(Constants.COVERAGE_PATH);
            } else {
                history.push(Constants.OWNER_PATH);
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const insuredOwnerSame = watch('insuredOwnerSame', 'true');
    const insuredEnglishPrimaryLanguageFlag = watch('insuredEnglishPrimaryLanguageFlag', 'true');
    const insuredEnglishFluencyFlag = watch('insuredEnglishFluencyFlag', 'true');

    return (
        <div className="container pds-container">
            <StepIndicator step={Constants.INSURED_STEP}
                isInsuredOwnerSame={insuredOwnerSame}
            />
            <br />
            <div className="col-sm-9 col-lg-10">
                <div className="row">
                    <p className="pds-typography-h3">Proposed Insured</p>
                </div>
                <div className="row">
                    <b>This process is not available for the state of NY.</b>
                </div>
            </div>
            <div className="row pds-util-float-right pds-util-padding-right-12">
                <label htmlFor="required">
                    <span className="pds-label-required">*</span>
                    <small className="pds-util-float-right" id="required">Required</small>
                </label>
            </div>
            <br />
            <form name="forms.insured" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-lg-4" style={{ display: 'none' }}>
                        <TextField id="insuredTitle"
                            labelText="Title"
                            name="insuredTitle"
                            type="text"
                            defaultValue=''
                            register={register}
                        />
                    </div>
                    <div className="col-lg-4">
                        <TextField id="insuredFirstName"
                            labelText="First name"
                            name="insuredFirstName"
                            type="text"
                            defaultValue={props.requestData.insuredFirstName}
                            register={register}
                            errors={errors}
                            required
                        />
                    </div>
                    <div className="col-lg-4">
                        <TextField id="insuredMiddleName"
                            labelText="Middle name"
                            name="insuredMiddleName"
                            type="text"
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.insuredMiddleName}
                        />
                    </div>
                    <div className="col-lg-4">
                        <TextField id="insuredLastName"
                            labelText="Last name"
                            name="insuredLastName"
                            type="text"
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.insuredLastName}
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-4">
                        <MaskedInput id="insuredDateOfBirth"
                            labelText="Date of birth"
                            name="insuredDateOfBirth"
                            placeHolder="MM/DD/YYYY"
                            mask={Constants.DATE_OF_BIRTH_MASK}
                            defaultValue={props.requestData.insuredDateOfBirth}
                            control={control}
                            errors={errors}
                            required
                        />
                    </div>
                    <div className="col-lg-4">
                        <MaskedInput id="insuredSsn"
                            labelText="Social security number"
                            name="insuredSsn"
                            mask={Constants.FULL_SSN_MASK}
                            defaultValue={props.requestData.insuredSsn}
                            control={control}
                            errors={errors}
                            onBlur={Constants.maskSsn}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-10">
                        <label htmlFor="insuredGender">
                            <b>Gender </b>
                            <span className="pds-label-required">*</span>
                        </label>
                        <br />
                    </div>
                    <div className="row col-lg-10">
                        <RadioField name="insuredGender"
                            values={Constants.GENDER}
                            defaultChecked={props.requestData.insuredGender}
                            register={register}
                            errors={errors}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12 col-md-11 col-lg-9">
                        <TextField id="insuredPostalAddressLine1"
                            labelText="Street address"
                            name="insuredPostalAddressLine1"
                            placeHolder="Address line 1"
                            type="text"
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.insuredPostalAddressLine1}
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12 col-md-11 col-lg-9">
                        <TextField id="insuredPostalAddressLine2"
                            name="insuredPostalAddressLine2"
                            placeHolder="Address line 2"
                            type="text"
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.insuredPostalAddressLine2}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-7 col-md-8 col-lg-3">
                        <TextField id="insuredPostalAddressCity"
                            labelText="City"
                            name="insuredPostalAddressCity"
                            type="text"
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.insuredPostalAddressCity}
                            required
                        />
                    </div>
                    <div className="col-sm-4 col-md-3 col-lg-3">
                        <SelectField id="insuredPostalAddressState"
                            labelText="State"
                            name="insuredPostalAddressState"
                            options={Constants.STATES}
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.insuredPostalAddressState}
                            required
                        />
                    </div>
                    <div className="col-sm-5 col-md-6 col-lg-3">
                        <MaskedInput id="insuredPostalAddressZip"
                            labelText="Zip"
                            name="insuredPostalAddressZip"
                            mask={Constants.ZIP_MASK}
                            defaultValue={props.requestData.insuredPostalAddressZip}
                            control={control}
                            errors={errors}
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <MaskedInput id="insuredPhoneNumber"
                            labelText="Phone number"
                            name="insuredPhoneNumber"
                            placeHolder="(999) 999-9999"
                            mask={Constants.PHONE_MASK}
                            defaultValue={props.requestData.insuredPhoneNumber}
                            control={control}
                            errors={errors}
                            required
                        />
                    </div>
                    <div className="row col-sm-12 col-md-6 col-lg-6">
                        <div className="col-lg-10">
                            <label htmlFor="insuredPhoneType">
                                <b>Is this a mobile number? </b>
                                <span className="pds-label-required">*</span>
                            </label>
                            <br />
                        </div>
                        <div className="row col-lg-10">
                            <RadioField name="insuredPhoneType"
                                values={Constants.YES_NO_INPUTS}
                                register={register}
                                errors={errors}
                                defaultChecked={props.requestData.insuredPhoneType}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12 col-md-10 col-lg-6">
                        <TextField id="insuredEmailAddress"
                            labelText="Email address"
                            name="insuredEmailAddress"
                            type="text"
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.insuredEmailAddress}
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-10">
                        <label htmlFor="insuredEnglishPrimaryLanguageFlag">
                            <b>Is English primary/native language of the proposed insured? </b>
                            <span className="pds-label-required">*</span>
                        </label>
                    </div>
                    <div className="row col-lg-10">
                        <RadioField name="insuredEnglishPrimaryLanguageFlag"
                            values={Constants.YES_NO_INPUTS}
                            register={register}
                            errors={errors}
                            defaultChecked={props.requestData.insuredEnglishPrimaryLanguageFlag}
                        />
                    </div>
                </div>
                <br />
                {insuredEnglishPrimaryLanguageFlag === 'false' && (
                    <>
                        <div className="row">
                            <div className="col-lg-10">
                                <label htmlFor="insuredEnglishFluencyFlag">
                                    <b>Is proposed insured fluent in reading, speaking, and understanding English? </b>
                                    <span className="pds-label-required">*</span>
                                </label>
                            </div>
                            <div className="row col-lg-10">
                                <RadioField name="insuredEnglishFluencyFlag"
                                    values={Constants.YES_NO_INPUTS}
                                    register={register}
                                    errors={errors}
                                    defaultChecked={props.requestData.insuredEnglishFluencyFlag}
                                />
                            </div>
                        </div>
                        <br />
                        {insuredEnglishFluencyFlag === 'false' && (
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-8 col-lg-6">
                                        <SelectField id="insuredPrimaryLanguage"
                                            labelText="What is the primary language of the proposed insured?"
                                            name="insuredPrimaryLanguage"
                                            options={Constants.LANGUAGES}
                                            register={register}
                                            errors={errors}
                                            defaultValue={props.requestData.insuredPrimaryLanguage}
                                            required
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="pds-card pds-alert pds-alert-information">
                                    <div className="pds-card-section pds-util-padding-vert-8">
                                        <div className="pds-alert-content" role="alert">
                                            <b>Based on the above response, please submit the Statement of English Understanding form, DD 992.</b>
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </>
                        )}
                    </>
                )}
                <div className="row">
                    <div className="col-lg-10">
                        <label htmlFor="partBLifeDiCombo">
                            <b>Would you like to have the Part B completed as a 'combo' for both Life and Disability Insurance? </b>
                            <span className="pds-label-required">* </span>
                        </label>
                        <a href="#"
                            className="pds-link-unstyled"
                            data-toggle="tooltip"
                            title="Please note that the Part B for both Life and Disability will be completed for the state selected during this drop ticket submission process"
                        >
                            <Icon name="info" size={16} />
                        </a>
                    </div>
                    <div className="row col-lg-10">
                        <RadioField name="partBLifeDiCombo"
                            values={Constants.YES_NO_INPUTS}
                            register={register}
                            errors={errors}
                            defaultChecked={props.requestData.partBLifeDiCombo}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <label className="col-lg-9" htmlFor="specialCallingInstructions">
                        <b>
                            Special calling instructions: If you have any special scheduling needs or other instructions, enter them here.
                            <br />
                            The Drop Ticket submission will automatically schedule the call. You do not need to schedule the interview.
                        </b>
                    </label>
                    <TextAreaField id="specialCallingInstructions"
                        name="specialCallingInstructions"
                        register={register}
                        errors={errors}
                        defaultValue={props.requestData.specialCallingInstructions}
                    />
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-10">
                        <label htmlFor="insuredOwnerSame">
                            <b>Is the proposed insured the owner? </b>
                            <span className="pds-label-required">*</span>
                        </label>
                    </div>
                    <div className="row col-lg-10">
                        <RadioField name="insuredOwnerSame"
                            values={Constants.YES_NO_INPUTS}
                            register={register}
                            errors={errors}
                            defaultChecked={props.requestData.insuredOwnerSame}
                        />
                    </div>
                </div>
                <br />
                <ExistingInsurance register={register}
                    watch={watch}
                    fields={fields}
                    append={append}
                    remove={remove}
                    idPrefix="insured"
                    labelFor="proposed insured"
                    errors={errors}
                    hasReplacementError={(replacementErrorFlag: boolean) => setReplacementErrorResponse(replacementErrorFlag)}
                    requestData={props.requestData}
                    setRequest={props.setRequest}
                />
                <div className="row col-sm-12 col-lg-12">
                    <button className="pds-button pds-button-primary" type="submit">Next</button>
                </div>
                <br />
            </form>
        </div>
    );
}

Insured.propTypes = {
    setRequest: PropTypes.func.isRequired,
    requestData: PropTypes.any.isRequired
};

export default Insured;
